<!--统计报表-检测仪总体情况报表-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-cascader
            v-model="filter.Owner"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="peorid"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.sumGroupByDates"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55" />
        <el-table-column
          prop="AcquitDate"
          label="采集时间"
          width="133"
          align="center"
          header-align="center"
        />
        <el-table-column prop="Total" label="设备总数" align="center" header-align="center" />
        <el-table-column
          prop="ComplianceCount"
          label="达标数"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column label="达标比例" prop="CompliancePercent" align="center" header-align="center">
        </el-table-column>
        <el-table-column prop="ExceedStandardCount" label="超标数" align="center" header-align="center" />
        <el-table-column label="超标比例" prop="ExceedStandardPercent" align="center" header-align="center">
        </el-table-column>
        <el-table-column label="在线数" prop="OnlineCount" align="center" header-align="center">
        </el-table-column>
        <el-table-column label="在线比例" prop="OnlinePercent" align="center" header-align="center">
        </el-table-column>
      </el-table>
    </el-col>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { dateFormater, join } from "@/util/index";

export default {
  data() {
    return {
      loading: false,
      datalist: {},
      filter: {
        Owner: null,
      },
      peorid: [],
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    dateFormat(r, c) {
      return dateFormater(r[c.property], false);
    },
    getData: function () {
      this.loading = true;
      var para = {
        Typ: 1,
        WithAreaLocaleReport: false
      };
      if (this.filter.Owner) {
        para["Owner"] = join(this.filter.Owner);
      }
      if (!this.peorid || !this.peorid.length) {
        const day = new Date();
        const yy = day.getFullYear();
        const mm = (day.getMonth() + 1) < 10 ? (`0${day.getMonth() + 1}`) : day.getMonth() + 1;
        const dd = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate();
        const last = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
        const lyy = last.getFullYear();
        const lmm = (last.getMonth() + 1) < 10 ? (`0${last.getMonth() + 1}`) : last.getMonth() + 1;
        const ldd = last.getDate() < 10 ? `0${last.getDate()}` : last.getDate();
        this.peorid[0] = `${lyy}-${lmm}-${ldd}`;
        this.peorid[1] = `${yy}-${mm}-${dd}`;
      }
      para["AcquitDateBegin"] = this.peorid[0];
      para["AcquitDateEnd"] = this.peorid[1];
      this.$post("admin/listDataDetectorDailyStats", para).then((res) => {
        this.datalist = res;
        this.loading = false;
      });
    },
    handleRefresh() {
      this.filter.Owner = null;
      this.getData();
    },
  },
};
</script>
